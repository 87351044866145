var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('b-row',[_c('b-col',{attrs:{"md":4}},[_c('b-form-group',{attrs:{"id":"item-gas-stations-group","label":_vm.$t('MENU.ITEM.TANKS.GAS_STATION'),"label-for":"item-assignor-input"}},[_c('FormSelect',{attrs:{"id":"item-gas-stations-input","type":"select","clearable":"","options":_vm.gasStations,"validateState":_vm.validateState('gasTankStationId')},model:{value:(_vm.form.gasTankStationId),callback:function ($$v) {_vm.$set(_vm.form, "gasTankStationId", $$v)},expression:"form.gasTankStationId"}})],1)],1),_c('b-col',{attrs:{"md":4}},[_c('b-form-group',{attrs:{"id":"item-bill-number-group","label":_vm.$t('FORM_LABELS.INVOICE_NUMBER'),"label-for":"item-bill-number-input"}},[_c('div',{staticClass:"input-group"},[_c('FormInput',{attrs:{"id":"bill-invoice-number-input","type":"text","name":"bill_number","i18n":{
              label: 'FORM_LABELS.INVOICE_NUMBER',
              placeholder: _vm.$t('FORM_LABELS.INVOICE_NUMBER'),
            },"validateState":_vm.validateState('invoiceNumber')},model:{value:(_vm.form.invoiceNumber),callback:function ($$v) {_vm.$set(_vm.form, "invoiceNumber", $$v)},expression:"form.invoiceNumber"}})],1)])],1),_c('b-col',{attrs:{"md":4}},[_c('b-form-group',{attrs:{"id":"item-bill-number-group","label":_vm.$t('FORM_LABELS.DATE'),"label-for":"item-bill-number-input"}},[_c('FormDatepicker',{attrs:{"id":"invoice-date-input","type":"text","validateState":_vm.validateState('invoiceDate')},model:{value:(_vm.form.invoiceDate),callback:function ($$v) {_vm.$set(_vm.form, "invoiceDate", $$v)},expression:"form.invoiceDate"}})],1)],1)],1),(_vm.form.rows.length > 0)?_c('b-row',[_c('b-col',{attrs:{"md":2}},[_c('div')]),_c('b-col',{attrs:{"md":4}},[_c('div',{staticClass:"text-right"},[_c('p',{staticClass:"pt-8"},[_c('b',[_vm._v("Стойност на фактурата:")])])])]),_c('b-col',{attrs:{"md":2}},[_c('b-form-group',{attrs:{"id":"item-bill-measure-group","label":_vm.$t('FORM_LABELS.VALUE_NO_VAT'),"label-for":"item-bill-total-value"}},[_c('div',{staticClass:"input-group"},[_c('FormInput',{attrs:{"id":"bill-invoice-total-value","type":"number","name":"bill_total_value","disabled":"","i18n":{
              label: 'FORM_LABELS.VALUE_NO_VAT',
              placeholder: _vm.$t('FORM_LABELS.VALUE_NO_VAT'),
            },"validations":'validations: {required: false, minLength: 0}',"validateState":{}},model:{value:(_vm.form.totalNoVat),callback:function ($$v) {_vm.$set(_vm.form, "totalNoVat", $$v)},expression:"form.totalNoVat"}})],1)])],1),_c('b-col',{attrs:{"md":2}},[_c('b-form-group',{attrs:{"id":"item-bill-measure-group","label":_vm.$t('FORM_LABELS.VALUE_VAT'),"label-for":"item-bill-qty-input"}},[_c('div',{staticClass:"input-group"},[_c('FormInput',{attrs:{"id":"bill-invoice-vat","type":"number","name":"vat","disabled":"","i18n":{
              label: 'FORM_LABELS.VAT',
              placeholder: _vm.$t('FORM_LABELS.VALUE_VAT'),
            },"validations":'validations: {required: false, minLength: 0}',"validateState":{}},model:{value:(_vm.form.vat),callback:function ($$v) {_vm.$set(_vm.form, "vat", $$v)},expression:"form.vat"}})],1)])],1),_c('b-col',{attrs:{"md":2}},[_c('b-form-group',{attrs:{"id":"item-bill-measure-group","label":_vm.$t('FORM_LABELS.VALUE_WITH_VAT'),"label-for":"item-bill-qty-input"}},[_c('div',{staticClass:"input-group"},[_c('FormInput',{attrs:{"id":"bill-invoice-w-vat","type":"number","name":"withVat","disabled":"","i18n":{
              label: 'FORM_LABELS.VALUE_WITH_VAT',
              placeholder: _vm.$t('FORM_LABELS.VALUE_WITH_VAT'),
            },"validations":'validations: {required: false, minLength: 0}',"validateState":{}},model:{value:(_vm.form.totalWithVat),callback:function ($$v) {_vm.$set(_vm.form, "totalWithVat", $$v)},expression:"form.totalWithVat"}})],1)])],1)],1):_vm._e(),_c('FullExpensesRowsTable',{attrs:{"form":_vm.form,"items":_vm.form.rows},on:{"add":_vm.handleAddRow}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }