<template>
  <v-card flat>
    <b-row>
      <b-col :md="4">
        <b-form-group
          id="item-gas-stations-group"
          v-bind:label="$t('MENU.ITEM.TANKS.GAS_STATION')"
          label-for="item-assignor-input"
        >
          <FormSelect
            id="item-gas-stations-input"
            type="select"
            clearable
            :options="gasStations"
            v-model="form.gasTankStationId"
            :validateState="validateState('gasTankStationId')"
          ></FormSelect>
        </b-form-group>
      </b-col>
      <b-col :md="4">
        <b-form-group
          id="item-bill-number-group"
          :label="$t('FORM_LABELS.INVOICE_NUMBER')"
          label-for="item-bill-number-input"
        >
          <div class="input-group">
            <FormInput
              id="bill-invoice-number-input"
              type="text"
              name="bill_number"
              :i18n="{
                label: 'FORM_LABELS.INVOICE_NUMBER',
                placeholder: $t('FORM_LABELS.INVOICE_NUMBER'),
              }"
              v-model="form.invoiceNumber"
              :validateState="validateState('invoiceNumber')"
            ></FormInput>
          </div>
        </b-form-group>
      </b-col>
      <b-col :md="4">
        <b-form-group
          id="item-bill-number-group"
          :label="$t('FORM_LABELS.DATE')"
          label-for="item-bill-number-input"
        >
          <FormDatepicker
            id="invoice-date-input"
            type="text"
            v-model="form.invoiceDate"
            :validateState="validateState('invoiceDate')"
          ></FormDatepicker>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="form.rows.length > 0">
      <b-col :md="2"><div></div></b-col>
      <b-col :md="4">
        <div class="text-right">
          <p class="pt-8"><b>Стойност на фактурата:</b></p>
        </div>
      </b-col>
      <b-col :md="2">
        <b-form-group
          id="item-bill-measure-group"
          :label="$t('FORM_LABELS.VALUE_NO_VAT')"
          label-for="item-bill-total-value"
        >
          <div class="input-group">
            <FormInput
              id="bill-invoice-total-value"
              type="number"
              name="bill_total_value"
              disabled
              :i18n="{
                label: 'FORM_LABELS.VALUE_NO_VAT',
                placeholder: $t('FORM_LABELS.VALUE_NO_VAT'),
              }"
              :validations="'validations: {required: false, minLength: 0}'"
              :validateState="{}"
              v-model="form.totalNoVat"
            ></FormInput>
          </div>
        </b-form-group>
      </b-col>
      <!-- <b-col :md="2">
        <b-form-group
          id="item-bill-measure-group"
          :label="$t('FORM_LABELS.VAT')"
          label-for="item-bill-qty-input"
        >
          <div class="input-group">
            <FormSwitch
              id="bill-invoice-vat-switch"
              type="checkbox"
              :name="'vat-switch'"
              :i18n="{
                label: 'FORM_LABELS.VAT',
                placeholder: $t('FORM_LABELS.VAT'),
              }"
              :validations="'validations: {required: false, minLength: 0}'"
              :validateState="{}"
              v-model="vat"
              @input="switchVat($event)"
            ></FormSwitch>
          </div>
        </b-form-group>
      </b-col> -->
      <b-col :md="2">
        <b-form-group
          id="item-bill-measure-group"
          :label="$t('FORM_LABELS.VALUE_VAT')"
          label-for="item-bill-qty-input"
        >
          <div class="input-group">
            <FormInput
              id="bill-invoice-vat"
              type="number"
              name="vat"
              disabled
              :i18n="{
                label: 'FORM_LABELS.VAT',
                placeholder: $t('FORM_LABELS.VALUE_VAT'),
              }"
              :validations="'validations: {required: false, minLength: 0}'"
              :validateState="{}"
              v-model="form.vat"
            ></FormInput>
          </div>
        </b-form-group>
      </b-col>
      <b-col :md="2">
        <b-form-group
          id="item-bill-measure-group"
          :label="$t('FORM_LABELS.VALUE_WITH_VAT')"
          label-for="item-bill-qty-input"
        >
          <div class="input-group">
            <FormInput
              id="bill-invoice-w-vat"
              type="number"
              name="withVat"
              disabled
              :i18n="{
                label: 'FORM_LABELS.VALUE_WITH_VAT',
                placeholder: $t('FORM_LABELS.VALUE_WITH_VAT'),
              }"
              :validations="'validations: {required: false, minLength: 0}'"
              :validateState="{}"
              v-model="form.totalWithVat"
            ></FormInput>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <FullExpensesRowsTable
      :form="form"
      :items="form.rows"
      @add="handleAddRow"
    ></FullExpensesRowsTable>
  </v-card>
</template>

<script>
import { FETCH_GAS_STATIONS } from "@/modules/gas-stations/store/gasstation.module";

import FormInput from "@/view/content/forms/components/FormInput.vue";
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";
import FormSelect from "@/view/content/forms/components/FormSelect";
import FormSwitch from "@/view/content/forms/components/FormSwitch";
import FullExpensesRowsTable from "./FullExpensesRowsTable";

import { validationMixin } from "vuelidate";
import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

export default {
  name: "FuelExpensesForm",
  mixins: [validationMixin, formBuilderMixin],
  components: {
    FormSelect,
    FormInput,
    FormSwitch,
    FormDatepicker,
    FullExpensesRowsTable,
  },
  props: {
    form: Object,
  },
  validations() {
    const tmpValidationObject = {
      form: {
        gasTankStationId: this.setItemValidations({
          required: true,
        }),
        invoiceNumber: this.setItemValidations({
          required: true,
        }),
        invoiceDate: this.setItemValidations({
          required: true,
          minLength: 2,
        }),
      },
    };

    return tmpValidationObject;
  },
  data() {
    return {
      vat: true,
      vatValue: 0.2,
      gasStations: [],
    };
  },
  computed: {},
  mounted() {
    this.$store.dispatch(FETCH_GAS_STATIONS).then((data) => {
      this.gasStations = data.data.items;
    });
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    switchVat(ev) {
      this.vatValue = ev ? 0.2 : 0;
      this.totalQty();
    },
    handleAddRow() {
      this.$emit("add-row");
    },
  },
};
</script>

<style type="text/css">
.input-lg input {
  font-size: 14px;
  font-weight: bold;
}
.vs__dropdown-toggle {
  background-color: #fff;
}
#item-bol-list-group label {
  font-weight: bold;
  font-size: 15px;
}

.sort_div_holder {
  position: relative;
}
.sort_div {
  display: inline-block;
  padding: 16px 10px;
}
.sort_div_filter {
  position: absolute;
  top: 8px;
  right: -34px;
}

.v-list--dense .v-list-item,
.v-list-item--dense {
  min-height: 20px !important;
  height: 2rem;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 0.5rem !important;
}

.v-list-item--link {
  transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.v-list-item--link:hover {
  background-color: rgba(0, 0, 0, 0.13);
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 13px !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > th.sortable {
  position: relative;
}
.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
}
.v-data-table-header__icon {
  height: 1px !important;
  float: right !important;
  top: -27px !important;
  right: -3px;
}
@media (min-width: 1264px) {
  .container {
    max-width: 100% !important;
  }
}
</style>
